import { useCrudItem } from "../../toolympus/api/useSimpleCrud";

const entryProfile = () => ({
    _id: "",
    email: "",

    lastname: "",
    firstname: "",

    company: "",
    position: "",

    bio: "",
    
    consent_share_profile: false,
    consent_email_show: false,
    title: "",
    interests: "",
    linkedin_url: "",
    consent_email_org: false,
});

export const useProfile = () => {
    const x = useCrudItem("/api/profile", {
      defaultValue: entryProfile(),
    })
    

    return {
      ...x,
    };
}
