import React from "react";
import { participantName } from "../../Participants/presentation/display";
import { Schedule } from "../../CourseManagement/types";
import { downloadCalendar } from "./AddToCalendar";

import {
  AddToCalendarButton,
  Block,
  ScheduleBlock,
  ShortTitle,
  Title,
  Tutor,
} from "./AllSchedules.style";
import { formatTime } from "./dates";
import moment from "moment";
import { ExternalLink } from "../../AboutCourse/presentation/AboutCoursePage.style";
import { WarningOutlined } from "@mui/icons-material";

export const ScheduleComponent = (props: {schedule: Schedule, showTime?: boolean }) => {
  const { course, tutors, start_datetime, end_datetime, title } = props.schedule;
  return (
    <ScheduleBlock duration={getDuration(props.schedule)}>
      <Block light>
        <div>
          <Title to={`/public/courses/${course?._id}`}>{course?.title || ""}</Title>

          {(tutors || []).map(tutor => <Tutor>{participantName(tutor)}</Tutor>)}
        </div>

        <ShortTitle>
            {props.showTime && <>
                {formatTime(start_datetime)} – {formatTime(end_datetime)}<br/>
                </>}
            {title}
        </ShortTitle>
        {props.schedule.external_link &&
          <ExternalLink href={props.schedule.external_link}>
            <WarningOutlined /> <span>takes place <span>outside the platform</span></span>
          </ExternalLink>}
      </Block>
      <AddToCalendarButton onClick={downloadCalendar([props.schedule])}>
        + Add to calendar
      </AddToCalendarButton>
    </ScheduleBlock>
  );
};

const getDuration = (event: Schedule) => {
  return moment(event.end_datetime).diff(moment(event.start_datetime), "minutes");
}

export const BreakBlock = ({ event }: { event: Schedule }) => {
  return <Block duration={getDuration(event)}>{formatTime(event.start_datetime)} – {formatTime(event.end_datetime)}: Break</Block>
}

export const EventBlock = ({ event }: { event: Schedule }) => {
  if(event.isBreak) {
    return <BreakBlock event={event} />;
  } else {
    return <ScheduleComponent schedule={event as any} showTime />;
  }
}