import { useTextFilter } from "../../toolympus/components/schemed/Filtering/useTextFilter";
import { useLoadedData } from "../../toolympus/hooks/useLoadedData";
import { Tutor } from "../CourseManagement/types";
import { useNewTutor } from "./presentation/useNewTutor";

export const useTutorsList = () => {
  const data = useLoadedData<Tutor[]>("/api/participant?view=tutors", [])
  const filter = useTextFilter<Tutor>(t => `${t.lastname} ${t.firstname}`);

  const newTutor = useNewTutor();

  return {
      ...data,
      data: filter.filterData(data.data),
      filter,
      newTutor,
  }
}

export type TutorListData = ReturnType<typeof useTutorsList>;
