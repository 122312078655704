import { IconButton, TableContainer } from '@mui/material'
import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { LoadingIndicator } from '../../../toolympus/components/primitives/LoadingIndicator'
import { TableForFields } from '../../../toolympus/components/schemed'
import { TableAddIcon } from '../../common/TableAddIcon'
import { NewTutorDialog } from './NewTutorDialog'
import { TutorSchema } from './TutorSchema'
import { ActionRow, OccupyFreeSpace, SearchField } from '../../../toolympus/components/primitives'
import { PersonOutlined } from '@mui/icons-material'
import { TutorListData } from '../useTutorsList'


interface Props {
    data: TutorListData;
}

export const TutorsListPage = (props: Props) => {

    const { isLoading, newTutor, data, filter } = props.data;
    const history = useHistory();


    return (
      <TableContainer>
        <ActionRow itemMarginTop="0" firstItemNoMargin>
          <TableAddIcon onClick={() => newTutor.startCreating()} />
          {isLoading && <LoadingIndicator sizeVariant="s" />}
          
          <OccupyFreeSpace />
          <SearchField
            filter={filter.filter}
            setFilter={filter.setFilter}
            noButton
            autoFocus
            />
        </ActionRow>
        
        <TableForFields 
            fields={[
              ["lastname"],
              ["firstname"],
              ["user", { width: 50, label: " " }],
            ]}
            fieldElement={f => {
              switch(f) {
                case "user":
                  return row => <Link onClick={e => { e.stopPropagation(); }} to={`/system/users?filter=${row._id}`}><IconButton size="small"><PersonOutlined /></IconButton></Link>
              }
            }}
            schema={TutorSchema}
            data={data}
            onRowClick={({_id}) => history.push('/tutor/' + _id)}
        />

        <NewTutorDialog data={newTutor} />
      </TableContainer>)
}