import React from 'react'
import {LoadingIndicator} from '../../../toolympus/components/primitives/LoadingIndicator'
import {Course, FileData} from '../types'
import {
    AboutTutor, Actions,
    CourseContainer, ExternalLink, FileLink, Files,
    Kind,
    LinkBack,
    MainTitle,
    ModeInformation, OneFile,
    ScheduleItem,
    Title,
    TutorAvatar,
    TutorInfo, TutorName
} from './AboutCoursePage.style'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import {Button, ButtonLink} from '../../common/Button';
import MessageSrc from './Message.svg';
import ProfileSrc from './Profile.svg';
import DownLoadFileSrc from './Download file.png';
import DeleteSrc from './Delete.png';
import {Schedule} from '../../CourseManagement/types';
import moment from 'moment';
import {useForm} from "../../common/useForm";
import {emptyCourse} from "../../CourseManagement/emptyObjects";
import {downloadFile} from "../../../toolympus/api/core";
import { participantName } from '../../Participants/presentation/display';
import { ActionRow, OccupyFreeSpace } from '../../../toolympus/components/primitives';
import { WarningOutlined } from '@mui/icons-material';

interface Api {
    save: (data: Course) => void;
    upload: (file: File) => void;
    delete: (id: string) => void;
}

interface FilesProps {
    data: FileData[];
    loading: boolean;
}

interface Props {
    data: {
        data: Course;
        loading: boolean;
        api?: Api;
        files: FilesProps;
    },
    schedules: {
        data: Schedule[];
        loading: boolean;
    },
    myCourse?: boolean;
}

export const AboutCoursePage = ({data: {loading, data, api, files}, schedules, myCourse}: Props) => {
    const form = useForm(data || emptyCourse());

    const saveWithSchedules = () => {
        if (!api) return;

        api.save(form.state);
    }

    if (loading && !data.title) {
        return <LoadingIndicator />
    }

    const openFileUploader = () => {
        const input = document.createElement('input') as HTMLInputElement;

        input.setAttribute('type', 'file');
        input.onchange = () => (api as Api).upload((input.files as FileList)[0]);
        input.click();
    }

    const downLoadFileFunction = (_id: string, filename: string) =>
        () => downloadFile(`/api/course/${data._id}/file/${_id}`, filename)

    return <CourseContainer>

        <MainTitle>
            {!api && <LinkBack to="/public/courses/"><KeyboardBackspaceIcon fontSize="small"/> Back</LinkBack>}
            <Kind>{data.kind}</Kind>
            {data.title}
        </MainTitle>
        <div>
            <Title>About Lecturer</Title>
            {(data.tutors || []).map(tutor => (
              <AboutTutor key={tutor._id}>
                  <TutorAvatar src={'/api/avatar/' + tutor._id}/>
                    <TutorInfo>
                      <TutorName>{participantName(tutor)}</TutorName>{tutor.position || tutor.company ? ", " + [tutor.position, tutor.company].filter(x => !!x).join(", ") : ""}
                    </TutorInfo>
                  <ButtonLink to={myCourse? '/my-profile' : ("/participants/" + tutor._id)}><img src={ProfileSrc} alt="profile"/></ButtonLink>
                  <ButtonLink to={'/public/message/' + tutor._id}><img src={MessageSrc} alt="message"/></ButtonLink>
              </AboutTutor>
            ))}
        </div>
        <div>
            <Title>Schedule</Title>
            {schedules.loading ?
                <LoadingIndicator/>
                :
                schedules.data.map(item => (
                    <ScheduleItem key={item._id}>
                        <span>{moment(item.start_datetime).format("dddd, D MMMM")}</span>
                        <span>{moment(item.start_datetime).format("H:mm")} – {moment(item.end_datetime).format("H:mm")}</span>

                        {item.external_link &&
                          <ExternalLink href={item.external_link}>
                            <WarningOutlined /> <span>takes place <span>outside the platform</span></span>
                          </ExternalLink>}
                    </ScheduleItem>
                ))
            }
        </div>
        {!!api?
            <div>
              {myCourse && <ActionRow itemMarginTop="0" lastItemMarginRight="0" style={{ marginBottom: "0.5rem" }}>
                <OccupyFreeSpace />
                <Button variant="contained" noFullWidth color="primary" onClick={saveWithSchedules}>Save changes</Button>
              </ActionRow >}

              {form.input('description', {label: <>Description <i>(optionally)</i></>, rows: 20, noRequired: true})}
            </div>
            :
            <div>
                <Title>More Information</Title>
                <ModeInformation>
                    {data.description}
                </ModeInformation>
            </div>
        }
        <Files>
            <Title>Files</Title>
            {files.data.map(({_id, filename}) =>
                <OneFile
                    key={_id}
                >
                    <FileLink >
                        {api && <img src={DeleteSrc} onClick={() => api.delete(_id)} alt={filename} />}
                        {!api && <img src={DownLoadFileSrc} onClick={downLoadFileFunction(_id, filename)} alt={filename} />}
                        <span onClick={downLoadFileFunction(_id, filename)}>
                            {filename}
                        </span>
                    </FileLink>
                </OneFile>)
            }
            {api && <Button onClick={openFileUploader} variant="outlined" color="primary">+ Add file</Button>}
            {files.loading && <LoadingIndicator />}
        </Files>
        {!myCourse && api &&
            <Actions>
                <Button variant="contained" color="primary" onClick={saveWithSchedules}>Save changes</Button>
            </Actions>
        }
    </CourseContainer>
}
